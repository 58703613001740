/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import api from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';

function Prestation() {
  const { id } = useParams();
  const [prestation, setPrestation] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    api
      .get(`/prestation/${id}`)
      .then((response) => {
        console.log('API Response:', response.data);
        setPrestation(response.data);
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  }, [id]);

  if (!prestation) {
    return <Typography>Chargement...</Typography>;
  }

  const imageUrl = `https://prodjason.store/uploads/images/${prestation.featuredImage}`;
  const cleanDescription = DOMPurify.sanitize(prestation.description);

  return (
    <Box className="prestation-container">
      <Box
        className="prestation-header"
        sx={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: isMobile ? '150px' : '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          component="h1"
          sx={{ color: '#fff', textAlign: 'center', padding: '0 10px' }}
        >
          {prestation.name}
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          variant="body1"
          component="div"
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            padding: 3,
            marginRight: 2,
            overflowWrap: 'break-word', // Empêche le débordement des mots longs
            '& img': {
              maxWidth: '100%', // Les images ne dépassent pas leur conteneur
              height: 'auto',
            },
          }}
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
        />
      </Box>
    </Box>
  );
}

export default Prestation;
